:root {
  --orange: #f7a54f;
  --blue: #66d9ef;
  --purple: #ae81ff;
  --whiteish: #f8f8f2;
  --blackish: #272822;

  // Others
  --grey-verydark: #555;
  --grey-darkened: #aaa;
  --grey-baseline: #bbb;
  --grey-lightened: #ccc;

  --lightblue: #effeff;

  --beige: #ecc;

  --main-bg-color: var(--whiteish);
  --main-txt-color: var(--blackish);

  --cta-bg: var(--orange);
  --cta-text: var(--blackish);
}

a {
  color: var(--blue);
}

a:visited {
  color: var(--purple);
}
