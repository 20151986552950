@import url(https://fonts.googleapis.com/css2?family=Unbounded:wght@400;700);

html,
body {
  font-size: 14pt;
  font-family: "Helvetica Neue", Arial, sans-serif;
  line-height: 1.75;
  height: 100%;
  accent-color: var(--main-txt-color);
  background: var(--main-bg-color);
  color: var(--main-txt-color);
}

* {
  box-sizing: border-box;
}

.App {
  display: grid;
  grid-template-columns:
    [left-column] minmax(1rem, auto)
    [main-column] minmax(350px, 800px)
    [right-column] minmax(1rem, auto);
  grid-template-rows: [nav-row] auto [main-row] 1fr [footer-row] auto;
  min-height: 100%;
}

header {
  margin-bottom: 1rem;
  grid-column: main-column;

  nav {
    margin-top: 1rem;
    display: grid;
    grid-column: language;
    grid-template-columns:
      [fi] auto
      [en] auto;
    button {
      display: block;
      border: none;
      margin: 0 0.5rem;
      background: none;
      text-decoration: underline;
      font-size: 1rem;
      color: var(--main-txt-color);
      cursor: pointer;
    }
  }

  @media only screen and (min-width: 700px) {
    display: grid;
    grid-template-columns:
      [heading] 1fr
      [language] auto;

    em {
      grid-column: heading;
    }

    nav {
      margin-top: 0;
    }
  }
}

h1,
h2,
h3,
h4 {
  font-family: Unbounded, "Helvetica Neue", Arial, sans-serif;
  font-weight: 700;
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
  margin-top: 1.5rem;
  border-bottom: 1px solid black;
}

h3 {
  font-size: 1.25rem;
  margin-top: 1.25rem;
}

h4 {
  font-size: 1rem;
  margin-top: 1rem;
  font-weight: 400;
}

main {
  grid-column: main-column;
}

.intro {
  font-size: 1.25rem;
  font-style: italic;
}

p {
  margin: 1rem 0;
}

a {
  color: initial;
  text-decoration: underline;
}

em {
  font-style: italic;
}

strong {
  font-weight: 700;
}

ul {
  margin: 1.25rem;
}

ul li {
  list-style-type: disc;
  margin: 0.5rem 0;
}

blockquote {
  margin: 1rem 0;
  border-left: 0.5rem solid var(--orange);
  padding-left: 1rem;
}

footer {
  margin-top: 2rem;
  bottom: 0;
  grid-column: main-column;
}

figure {
  max-width: 100%;
  img {
    max-width: 100%;
  }
}

figcaption,
figcaption a {
  color: var(--grey-verydark);
  font-style: italic;
  text-align: right;
}

.two-column {
  margin: 1rem 0;
  column-count: 2;

  p {
    margin: 0;
  }

  ul {
    margin: 0 1.25rem;
  }
}

@media screen and (max-width: 500px) {
  .two-column {
    column-count: 1;
  }
}

.cta {
  display: inline-block;
  background-color: var(--cta-bg);
  color: var(--cta-text);
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-family: Unbounded, "Helvetica Neue", Arial, sans-serif;
  margin-bottom: 1.25rem;
}

.cta:hover,
.cta:active,
.cta:focus {
  text-decoration: underline;
}
